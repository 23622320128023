.chartSvg {
	width: calc(100% - 0px);
	height: 100%;
}
.chartSvg1 {
	width: calc(100% - 0px);
	height: 100%;
	/* transform: translate(50%, 0); */
}
.custom-node:hover {
	fill: #1b5985;
}
