.site-tag-plus {
  background: #fff;
  border-style: dashed;
  cursor: pointer;
  border-radius: 2px;
  line-height: 1.5715;
}

.edit-tag {
  user-select: none;
  cursor: pointer;
  color: white;
  background: #27A6A4;
  border-color: #27a4a6;
  line-height: 1.5715;
  border: 1px solid transparent;
  font-size: 14px;
  border-radius: 2px;
}

.tag-input {
  width: 15%;
  margin-right: 8px;
  vertical-align: top;
  border-radius: 2px;
}