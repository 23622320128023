table {
	font-family: Arial, Helvetica, sans-serif;
	border-collapse: collapse;
	width: 100%;
	/* height: 100px; */
}

/* table td, */
table th {
	/* border-left: 1px solid #ddd; */
	padding: 0.8em 1em;
	font-size: 0.8rem;
	font-weight: 600;
}
table td {
	font-size: 0.9rem;
}
table thead {
	position: sticky;
	top: 0;
	/* z-index: 10; */
}

table thead::after {
	position: absolute;
	/* z-index: 10; */
	content: "";
	/* width: 100%; */
	height: 0.1em;
	background-color: #716e79;
}
/* table th:last-child {
	border-right: 1px solid #ddd;
} */
/* table thead tr {
	border-bottom: 1px solid #716e79;
} */
table td {
	padding: 0em 1em;
	border-bottom: 1px solid #dedcde;
}
/* table tr:nth-child(even) {
	background-color: #f2f2f2;
} */

/* table tr:hover {
	background-color: #ddd;
} */

table th,
tfoot td {
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: left;
	background-color: white;
	color: #454250;
}
.pagination {
	padding-top: 20px;
	position: absolute;
	bottom: 30px;
	right: 50px;
	display: flex;
	justify-content: space-between;
}
.tableSearch {
	display: flex;
	justify-content: left;
}
.pagination button {
	border: 1px solid rgb(187, 187, 187);
	border-radius: 4px;
	/* background: rgb(187, 187, 187); */
	/* color: #27a6a4; */
	cursor: pointer;
	margin: 0 4px;
	padding: 0 4px;
}
.tableSearch input,
.pagination input {
	border: 1px solid rgb(187, 187, 187);
	border-radius: 4px;
}
.pagination select {
	border: 1px solid rgb(187, 187, 187);
	border-radius: 4px;
}

table a,
table a:hover {
	color: #27a6a4;
	font-weight: 500;
}
table tbody td {
	color: #2f2c3c;
	font-weight: 400;
}
table tbody tr td {
	/* height: 100px; */
	height: 60px;
}
.scrollTable {
	position: relative;
	/* max-height: 345px; */
	overflow-y: auto;
}
