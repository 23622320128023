html {
  font-size: 20px;
}
@media (max-width: 2400px) {
  html {
    font-size: 18px;
  }
}
@media (max-width: 1980px) {
  html {
    font-size: 17px;
  }
}
@media (max-width: 1600px) {
  html {
    font-size: 16px;
  }
}
@media (max-width: 1024px) {
  html {
    font-size: 15px;
  }
}
@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

/* @media screen and (max-width: 2560px) {
	html {
		font-size: 20px;
	}
} */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* body::-webkit-scrollbar {
	display: none;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3,
h4,
h5,
h5 {
  line-height: 1;
  margin: 0;
}
p {
  margin: 0;
}
img {
  display: block;
  max-width: 100%;
}
.reach-check .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #27a6a4;
  border-color: #27a6a4;
}
.disabled-check .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #c0c0c0 !important;
  border-color: #c0c0c0 !important;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .tab-active {
    @apply text-text-teal
		pb-2
		border-b-4
		border-text-teal;
  }
}

.input-search input {
  height: 42px;
}
.ant-select-multiple .ant-select-selection-item-remove svg {
  margin-top: -4px;
}
.input-search button {
  height: 42px;
  width: 52px;
}
.green-button {
  background-color: #27a6a4 !important;
  border-color: #27a6a4 !important;
}
.modal-footer-left .ant-modal-footer {
  text-align: left !important;
}
.search-button {
  background-color: #27a6a4 !important;
  border-color: #27a6a4 !important;
  font-size: large !important;
  color: white !important;
  cursor: pointer;
}
.radio-custom .ant-radio-checked .ant-radio-inner {
  border-color: #27a6a4 !important ;
}
.radio-custom span {
  font-size: 0.875rem;
}
.radio-custom.top .ant-radio-checked .ant-radio-inner:after{
  background-color: #91d0ff;
}
.radio-custom.top .ant-radio-checked .ant-radio-inner {
  border-color: #91d0ff !important;
}
.radio-custom.medium .ant-radio-checked .ant-radio-inner:after{
  background-color: #e1dc6d;
}
.radio-custom.medium .ant-radio-checked .ant-radio-inner {
  border-color: #e1dc6d !important;
}
.radio-custom.low .ant-radio-checked .ant-radio-inner:after{
  background-color:#ffb4b4
}
.radio-custom.low .ant-radio-checked .ant-radio-inner {
  border-color: #ffb4b4 !important;
}

.radio-custom .ant-radio-checked .ant-radio-inner:after {
  background-color: #27a6a4;
}
.width-full.ant-skeleton {
  width: 100%;
}

.width-full.ant-skeleton-input {
  width: 100%;
}

.scaling-class:hover {
  transform: scale(1.05);
}

.rd3t-tree-container{
  height:65vh !important;
}

.ant-table-thead {
  z-index: 100;
}

.ant-breadcrumb-link {
  display: flex;
  align-items: center;
}

/* .searchRadio .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #27A4A6 !important;
}

.searchRadio .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: white;
  border-color: #27A4A6 !important;
  color: #27A4A6 !important;
} */

.searchRadio .ant-radio-button-wrapper:hover {
  color: #27A4A6 !important;
} 

.searchRadio .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: white !important;
  background: #27A4A6 !important;
  border-color: #27A4A6 !important;
}