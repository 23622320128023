.table-wrapper .ant-input-search input,
.table-wrapper .ant-input-search button {
	height: 2rem;
}

.transcript-text {
	padding-left: 10px;
	margin-bottom: 10px;
}
.contains-keyword {
	border-left: 5px solid #76bcee;
}

.contains-keyword span {
	background-color: #76bcee;
	padding: 0 1px;
}
