.ant-dropdown-menu {
    max-height: 600px;
    overflow-y: auto;
}

.ant-dropdown-menu-item {
    cursor: default;
}

.notification-card {
    width: 500px;
    cursor: default;
    padding: 4px 8px;
    border-radius: 8px;
    border-color: #27A6A4;
    position: relative;
}

.no-notification-card {
    font-size: 18px;
    color: grey;
    text-align: center;
}

.close-icon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
    cursor: pointer;
    color: grey;
}

.clear-all {
    color: #27A6A4;
    background: white;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 16px;
}

.bell-icon {
    font-size: 26px;
    color: white;
}