.ant-table-thead .ant-table-cell {
  background-color: #FAFAFA;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000D9;
}

.wave-cell-text {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000A6;
}

.geo-cell-text {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000A6;
}

.date-cell-text {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #1A1628;
}

.guide-cell-text {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #2F54EB;
  cursor: pointer;
  display: inline-block;
  width: 350px;
  /* Adjust the width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}





/* .editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.ant-table-layout-fixed table {
  table-layout: auto;
}

.select-button {
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-button-selected {
  border: 1px solid #1890ff;
  border-radius: 4px;
}

.selected-wave {
  border: 2px solid blue; /* Apply your desired border style 
  border-radius: 5px; /* Apply your desired border radius */
/* Add any other styles you want for the selected wave 
} */