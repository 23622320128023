.stories-body {
	height: 76vh;
	overflow-y: auto;
}

.discon {
	height: calc(100vh - 7rem);
	overflow-x: hidden;
	overflow-y: auto;
}

.border-left {
	border-left: 1px solid #DEDCDE;
}

.icon-width svg {
	width: 20px;
}

table th svg {
	margin-left: 5px;
}

.lasso path {
	stroke: rgb(80, 80, 80);
	stroke-width: 2px;
}

.lasso .drawn {
	fill-opacity: .05;
}

.lasso .loop_close {
	fill: none;
	stroke-dasharray: 4, 4;
}

.lasso .origin {
	fill: #3399FF;
	fill-opacity: .5;
}

.not_possible {
	fill: rgb(200, 200, 200);
}

.possible {
	fill: #3399FF;
}

.selected {
	fill: #3399FF;
}
#scatterPlot .axis line {
	stroke: #E7E7E7;
}

#scatterPlot .axis path {
	stroke: #E7E7E7;
}

#scatterPlot .axis text {
	fill: #53565A;
	/*font-family: 'Roboto'; */
	font-style: normal;
	font-weight: 500;
	font-size: 17.4804px;
	line-height: 27px;
}
#scatterPlot .axis .tick line {
	visibility: hidden;
}

.rate-graph div {
	margin: 0;
	padding: 0;
}

.rate-graph h3 {
	margin-top: 0;
}
.middle-text{
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
    color: #888;
}

.lasso-button.active svg *{
	fill: #27a6a4;
}
.lasso-button svg {
    width: 20px;
    height: 20px;
}