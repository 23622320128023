/* For Markdown text */
ol {
	display: block;
	list-style-type: decimal;
	margin-block-start: 0.5em;
	margin-block-end: 0.5em;
	margin-inline-start: 0em;
	margin-inline-end: 0em;
	padding-inline-start: 2.5em;
}
ul {
	display: block;
	list-style-type: disc;
	margin-block-start: 0.5em;
	margin-block-end: 0.5em;
	margin-inline-start: 0em;
	margin-inline-end: 0em;
	padding-inline-start: 2.5em;
}
ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0.8em;
}
h1 {
	font-size: 2em;
	font-weight: bold;
	line-height: 1.2;
}
h2 {
	font-size: 1.5em;
	font-weight: bold;
	line-height: 1.2;
}
h3 {
	font-size: 1.17em;
	font-weight: bold;
	line-height: 1.2;
}
h4 {
	font-size: 1em;
	font-weight: bold;
	line-height: 1.2;
}
h5 {
	font-size: 0.83em;
	font-weight: bold;
	line-height: 1.2;
}
h6 {
	font-size: 0.67em;
	font-weight: bold;
	line-height: 1.2;
}
