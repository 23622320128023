.ant-drawer-title {
	font-size: 1.1rem;
}
.ant-drawer-body {
	padding: 0;
}

.ant-drawer-close {
	margin-bottom: 2px;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #87848d;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #27a6a4;
}

.no-scroll-div::-webkit-scrollbar {
	display: none;
}
.no-scroll-div:hover::-webkit-scrollbar {
	display: inline-block;
}

.ant-checkbox-checked .ant-checkbox-inner {
	background-color: #27a6a4;
	border-color: #27a6a4 !important;
}
.ant-checkbox-inner {
	border-color: #27a6a4 !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
	border-color: #27a6a4 !important;
}

.ant-switch-checked {
	background-color: #27a6a4;
}
