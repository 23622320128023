/* NodeGraph.tsx */
.App {
  font-family: "Roboto", sans-serif;
  text-align: center;
}

.myLabelComponentInSvg {
  height: 150px;
  width: 180px;
  margin: 10px;
}

.node-container {
  position: relative; 
  height: 52px;
  width: 180px;
  text-align: center;
  z-index: 1000;
  box-shadow: 0px 1px 2px 0px #00000040;
  padding: 5px 0;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* overflow: hidden; */
}  

.node-name {
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-size: 12px;
  /* font-weight: 600; */
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  /* white-space: nowrap; */
}

.node-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: none;
  cursor: pointer;
  outline: none;
  margin-right: -6px;
}

.selected-node {
  border: 2px solid rgba(24, 143, 255, 0.5);
}

.leaf-selected-node {
  border: 2px solid #FFADD2; /* Change this to your desired border style */
}

.emerging-leaf-selected-node {
  border: 2px solid #B0738B; /* Change this to your desired border style */
}

.info-icon {
  position: absolute;
  top: 0;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: none;
  margin: 5px;
  z-index: 9999;
}

.info-text {
  position: absolute;
  top: 25px;
  right: -50px;
  width: 100px;
  background-color: #ffffff;
  box-shadow: 0px 1px 2px 0px #00000040;
  padding: 5px;
  border-radius: 2px;
  font-size: 12px;
  color: #2D8B93;
  z-index: 9999;
}

.node-number-box {
  background-color: #FFF0F6;
  color: #FFADD2;
  border: 1px solid #FFADD2;
  font-family: Roboto;
  font-size: 12px;
  /* font-weight: 400; */
  width: 25px;
  height: 15px;
  padding: 4px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.linkBase {
  stroke: #dedcde !important;
}

/* Node Summary Component */

.node-summary {
  background-color: #FFFFFF;
  padding: 10px;
  /* border: 1px solid #ddd; */
  border-radius: 4px;
  margin-top: 10px;
  /* position: fixed; */
  bottom: 0;
  left: 0;
  width: 100%;
  height: 140px;
  /* box-shadow: 0px -2px 4px 0px #00000040; */
  padding-bottom: 20px;
}

.node-summary-header {
  margin-bottom: 5px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #2F2C3C;
}

.node-summary-divider {
  border-top: 1px solid #ddd;
  margin: 10px 0;
}

.node-summary-body {
  margin-top: 10px;
}

.summary-label {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #1A1628;
}

.summary-text {
  display: block;
  margin-top: 10px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #716E79;
}

.node-summary-showmore {
  /* position: absolute; */
  bottom: 0;
  right: 0;
  font-family: Roboto;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  letter-spacing: 0em;
  text-align: left;
  color: #2F54EB;
  padding-bottom: 5px;
}


/* Discover.tsx */
.dropdown {
  width: 120px;
  margin-right: 10px;
}

.header {
  /* display: flex;
  align-items: center; */
  margin: 0 15px;
}

/* Additional styles for better visual appearance */
.label {
  margin-right: 5px;
  font-weight: bold;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: right;
}

.select-container {
  position: relative;
}

.select-container .ant-select-selector {
  height: 30px;
  line-height: 28px;
}

/* Make dropdown box round on edges */
.select-container .ant-select-selector,
.select-container .ant-select-selector .ant-select-selection-item {
    border-radius: 4px;
}

/* Style the arrow icon */
.select-container:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000;
  pointer-events: none;
}

/* Style the dropdown options */
.select-dropdown {
  position: absolute;
  z-index: 1;
  width: 120px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  list-style-type: none;
  margin: 0;
  top: 100%;
  left: 0;
  display: none;
  border-radius: 4px;
}

.select-dropdown li {
  padding: 5px;
  cursor: pointer;
}

/* Show the dropdown options on hover */
.select-container:hover .select-dropdown {
  display: block;
}
