.ant-card-body {
    padding: 0px
}

.ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    padding-top: 0px;
}

.ant-list-header {
    font-size: large;
    padding-top: 0px;
    font-weight: bold;
}

#parent {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    max-height: 80vh;
}

#div1 {
    display: flex;
    flex-direction: column;
    z-index: 9;
}

#div2 {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    z-index: 8;
}

#div2::-webkit-scrollbar-track {
    margin-top: 10vh;
}