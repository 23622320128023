.node-transcript-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative; /* To make the position of "Show More" link relative to this container */
}


.node-transcript-title {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #27A6A4;
    padding-bottom: 5px;
}

.node-transcript-body {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #716E79;
    padding-bottom: 5px;
}

.node-transcript-showmore {
    /* position: absolute; */
    bottom: 0;
    right: 0;
    font-family: Roboto;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #2F54EB;
    padding-bottom: 5px;
    margin-top: -15px;
}