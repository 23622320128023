.panel-header {
  width: 100%;
  display: grid;
  grid-template-columns: 35% 10% 15% 25% 10% 5%;
}
.ant-list-pagination .ant-pagination-item-active {
  border-color: #27a4a6;
}
.ant-list-pagination a {
  color: #27a4a6;
}
.ant-list-pagination .ant-pagination-prev button,
.ant-list-pagination .ant-pagination-next button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-popover .ant-btn-primary {
  background-color: #27a4a6;
  border-color: #27a4a6;
}

.annotationHead {
  display: inline-block;
  width: 540px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.highlight-text-container p {
	color: #5B5864; /* RGB values converted to hexadecimal */
	opacity: 1;
	padding-bottom: 0.25rem;
  padding-top: 0.25rem;
	font-family: Roboto;
	font-size: 1rem;
  line-height: 1.5rem;
}
