.highlighted-text {
	background-color: #ffe68c;
}
.highlighted-text {
	display: inline;
}
.highlight-text-container p {
	color: #5B5864; /* RGB values converted to hexadecimal */
	opacity: 1;
	padding-bottom: 0.25rem;
    padding-top: 0.25rem;
	font-family: Roboto;
	font-size: 1rem;
    line-height: 1.5rem;
}
/* .highlight-text-container p {
	font-size: 1rem;
	line-height: 1.5rem;
	margin-bottom: 1rem;
	color: #2f2c3c;
} */
.dropdown-select a {
	flex: none;
	padding-left: 4px;
	display: block;
	cursor: pointer;
}
.dropdown-select a:hover {
	color: #27a4a6;
}
/* ::selection {
	background: #ffe68c;
} */
