.customProgressItem .ant-slider {
	pointer-events: none;
}
.customProgressItem .ant-slider-rail,
.customProgressItem .ant-slider-track {
	height: 6px;
	border-radius: 6px;
}
.customProgressItem .ant-slider-track {
	background-color: #27a4a6;
}
.customProgressItem .ant-slider-handle {
	display: none;
}
