.ant-form .ant-btn {
	background-color: white;
}
.ant-form .ant-btn:hover {
	color: #27a4a6;
	border-color: #27a4a6;
}
.ant-form .ant-btn-primary {
	background-color: #27a4a6;
	border-color: #27a4a6;
}
.ant-form .ant-btn-primary:hover {
	color: rgb(189, 189, 189);
	border-color: #27a4a6;
}
.ant-form-item label {
	font-size: 1rem;
	font-weight: 500;
}
.ant-form-item .ant-picker {
	width: 100%;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
	background-color: #27a4a6;
	border-color: #27a4a6;
}
.ant-select-tree-checkbox-checked:hover {
	border: 1px solid #27a4a6;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
	background-color: #27a4a6;
	border: 1px solid #27a4a6;
}
.ant-form-item {
	margin-bottom: 1.25rem;
}

.ant-slider-rail,
.ant-slider-track {
	height: 6px;
	border-radius: 6px;
}
.ant-slider-track {
	background-color: #27a4a6;
}
.ant-slider:hover .ant-slider-track {
	background-color: #27a4a6;
}
