.dashboard {
	height: calc(100vh - 7rem);
	overflow-x: hidden;
	overflow-y: auto;
}
/* .dashboard::-webkit-scrollbar {
	display: none;
} */

/* .dashboard-body {
	height: 76vh;
	overflow-y: scroll;
}
.dashboard-body-subsubtab {
	height: 69vh;
	overflow-y: scroll;
}
.dashboard-body::-webkit-scrollbar {
	display: none;
}
.dashboard-body-subsubtab::-webkit-scrollbar {
	display: none;
} */
