.bkdImgSearch{
    /* background-image: url("./SearchEngine.jpg"); */
    background-image: url("./Application\ 6.jpg") ;
    background-size: cover;
    
}
.bkdImgDiscover{
    /* background-image: url("./SearchEngine.jpg"); */
    background-image: url("./Discover.jpg") ;
    background-size: cover;
}

.bkdImgApp3{
    /* background-image: url("./SearchEngine.jpg"); */
    background-image: url("./Application\ 3.jpg");
    background-size: cover;
    opacity: 0.25;
}

.bkdImgApp4{
    /* background-image: url("./SearchEngine.jpg"); */
    background-image: url("./Discover.jpg");
    background-size: cover;
    opacity: 0.25;
}
.bkdImgApp5{
    /* background-image: url("./SearchEngine.jpg"); */
    background-image: url("./Application\ 3.jpg");
    background-size: cover;
    opacity: 0.25;
}
.bkdImgApp6{
    /* background-image: url("./SearchEngine.jpg"); */
    background-image: url("./Application\ 6.jpg");
    background-size: cover;
    opacity: 0.25;
}
