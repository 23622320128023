th {
  background-color: #e0e0e0 !important;
  /* reqd just for Stories tab table header */
}

table thead {
  z-index: 200 !important;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:active,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
  background: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
  color: rgba(0, 0, 0, .25) !important;
  text-shadow: none !important
}