.modal-collapse .-collapse-header {
	font-size: 1.1rem !important;
}
.modal-collapse {
	background-color: #ffffff;
}

.site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
	margin-bottom: 24px;
	overflow: hidden;
	background: #f7f7f7;
	border: 0px;
	border-radius: 2px;
}
