/* @import "https://code.highcharts.com/css/highcharts.css";   */

.stories-body {
  min-height: 79vh;
  overflow-y: auto;
  position: relative;
}

.site-card-wrapper {
  padding: 30px;
  background: #ececec;
}

.InfoCard:hover {
  outline: 2px ridge #27a6a4;
  box-shadow: 0 4px 4px 0 #27a6a4, 0 6px 6px 0 #27a6a4;
  border-radius: 2px;
}

.InfoCardSelected {
  outline: 2px ridge #27a6a4;
  box-shadow: 0 4px 4px 0 #27a6a4, 0 6px 6px 0 #27a6a4;
  border-radius: 2px;
}

.InfoCard {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.transcript-ellipsed {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  padding-bottom: 7px;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
/* 
.highlight .highcharts-data-label-box {
  fill: red;
  border-color: red;
  stroke-width: 2px;
  stroke: black;
} */

.ant-card-bordered {
  border: 1px solid #dedcde;
  padding: 15px;
}

.node-tooltip {
  background: #252525;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  min-width: 250px;
  max-width: 250px;
  padding: 15px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
}

.node-tooltip.left-arrow:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #252525;
  top: 12px;
  left: -8px;
}

.node-tooltip.right-arrow:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #252525;
  top: 12px;
  left: auto;
  right: -8px;
}

.node-tooltip li {
  margin-bottom: 10px;
}

.node-tooltip li svg {
  margin-left: 12px;
  cursor: pointer;
}

.node-tooltip li input {
  font-weight: 600;
  max-width: 80px;
  padding: 3px 5px;
  background-color: transparent;
  border: 1px solid #ffffffab;
}

/* .node-tooltip li img {
	position: relative;
	display: inline-block;
  }
  

  .node-tooltip li img:hover::after {
	content: attr(title);
	position: absolute;
	top: -100%;
	left: 0;
  } */

.node-tooltip span {
  min-width: 90px;
}

.node-tooltip strong {
  font-weight: 600;
}

.node-tooltip .bottom-list strong {
  font-size: 10px;
  display: block;
  margin-top: 3px;
}

.node-tooltip .bottom-list li {
  margin-bottom: 0;
}

.positive {
  color: #43b969;
}

.negative {
  color: #e45151;
}

.neutral {
  color: #696868;
}

.check-tootip {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 5px;
  width: 225px;
  color: #000;
  letter-spacing: 0.04em;
  font-weight: 500;
  font-size: 11px;
  position: absolute;
  top: 50px;
  right: 0px;
}

.check-head {
  background: #dedede;
  border-radius: 5px 5px 0px 0px;
  min-height: 31px;
  align-items: center;
  padding: 2px 7px;
  font-weight: 600;
}

.check-tootip .ant-checkbox-inner {
  width: 15px;
  height: 15px;
}

.check-tootip .ant-checkbox + span {
  padding-right: 0;
}

.check-tootip label.ant-checkbox-wrapper {
  font-size: 11px;
  text-transform: capitalize;
  width: 100%;
}

.check-tootip .ant-checkbox-wrapper span:last-child {
  display: flex;
  flex: 100%;
}

.check-tootip .ant-checkbox-wrapper .count-text {
  width: 70px;
  padding: 2px 5px;
}

.check-head svg {
  width: 10px;
  height: auto;
}

.check-tootip .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #27a6a4;
}

.check-tootip label.ant-checkbox-wrapper:hover {
  color: #27a6a4;
}

.check-tootip .check-head .ant-checkbox-inner {
  width: 16px;
  height: 16px;
}

.check-body {
  padding: 6px 7px;
  max-height: 165px;
  overflow: auto;
  margin-bottom: 10px;
}

.graph-actions {
  position: absolute;
  top: 25px;
  right: 30px;
}

.graph-actions strong {
  font-size: 12px;
  margin-left: 30px;
}

.graph-actions strong button {
  margin-left: 10px;
}

.drivers-chart div {
  margin: 0;
  padding: 0;
}

.drivers-chart g {
  cursor: pointer;
}

.scroll-btn {
  font-weight: 500;
}

.scroll-btn svg {
  width: 20px;
  margin-right: 2px;
  height: 19px;
}

.tag-list .bg-acordion-description-tag {
  margin-bottom: 10px;
  font-size: 12px;
}

.nodegraph-page .ant-tag.ant-tag-has-color,
.discon .ant-tag.ant-tag-has-color {
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid rgb(167 167 167 / 85%);
}

.nodegraph-page .edit-icon {
  cursor: pointer;
}

.nodegraph-page .edit-icon svg {
  fill: #27a6a4;
  width: 23px;
}

.edit-text input {
  border: 1px solid #d9d9d9 !important;
  outline: none;
  padding: 3px 8px;
  border-radius: 1px;
}

.edit-text svg {
  width: 28px;
  fill: #27a6a4;
  margin-left: 5px;
  cursor: pointer;
}

.edit-text .close svg {
  margin-left: 5px;
  width: 20px;
  height: 20px;
  margin-bottom: 0 !important;
}

.node-tooltip {
	background: #252525;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	min-width: 250px;
	max-width: 250px;
	padding: 15px 10px;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #fff;
}

.node-tooltip.left-arrow:after {
	content: "";
	display: block;
	position: absolute;
	width: 0;
	height: 0;
	border-top: 7px solid transparent;
	border-bottom: 10px solid transparent;
	border-right: 10px solid #252525;
	top: 12px;
	left: -8px;
}

.node-tooltip.right-arrow:after {
	content: "";
	display: block;
	position: absolute;
	width: 0;
	height: 0;
	border-top: 7px solid transparent;
	border-bottom: 10px solid transparent;
	border-left: 10px solid #252525;
	top: 12px;
	left: auto;
	right: -8px;
}

.node-tooltip li {
	margin-bottom: 10px;
}

.node-tooltip li svg {
	margin-left: 12px;
	cursor: pointer;
}

.node-tooltip li input {
	font-weight: 600;
	max-width: 80px;
	padding: 3px 5px;
	background-color: transparent;
	border: 1px solid #FFFFFFAB;
}

/* .node-tooltip li img {
	position: relative;
	display: inline-block;
  }
  
  .node-tooltip li img:hover::after {
	content: attr(title);
	position: absolute;
	top: -100%;
	left: 0;
  } */

.node-tooltip span {
	min-width: 90px;
}

.node-tooltip strong {
	font-weight: 600;
}

.node-tooltip .bottom-list strong {
	font-size: 10px;
	display: block;
	margin-top: 3px;
}

.node-tooltip .bottom-list li {
	margin-bottom: 0;
}

.positive {
	color: #43B969;
}

.negative {
	color: #E45151;
}

.neutral {
	color: #696868;
}

.check-tootip {
	background: #FFFFFF;
	box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
	border-radius: 5px;
	width: 225px;
	color: #000;
	letter-spacing: 0.04em;
	font-weight: 500;
	font-size: 11px;
	position: absolute;
	top: 50px;
	right: 0px;

}

.check-head {
	background: #DEDEDE;
	border-radius: 5px 5px 0px 0px;
	min-height: 31px;
	align-items: center;
	padding: 2px 7px;
	font-weight: 600;
}

.check-tootip .ant-checkbox-inner {
	width: 15px;
	height: 15px;
}

.check-tootip .ant-checkbox+span {
	padding-right: 0;
}

.check-tootip label.ant-checkbox-wrapper {
	font-size: 11px;
	text-transform: capitalize;
	width: 100%;
}

.check-tootip .ant-checkbox-wrapper span:last-child {
	display: flex;
	flex: 100%;
}

.check-tootip .ant-checkbox-wrapper .count-text {
	width: 70px;
	padding: 2px 5px;
}

.check-head svg {
	width: 10px;
	height: auto;
}

.check-tootip .ant-checkbox-indeterminate .ant-checkbox-inner::after {
	background-color: #27a6a4;
}

.check-tootip label.ant-checkbox-wrapper:hover {
	color: #27a6a4;
}

.check-tootip .check-head .ant-checkbox-inner {
	width: 16px;
	height: 16px;
}

.check-body {
	padding: 6px 7px;
	max-height: 165px;
	overflow: auto;
	margin-bottom: 10px;
}

.graph-actions {
	position: absolute;
	top: 25px;
	right: 30px;
}

.graph-actions strong {
	font-size: 12px;
	margin-left: 30px;
}

.graph-actions strong button {
	margin-left: 10px;
}

.drivers-chart div {
	margin: 0;
	padding: 0;
}

.drivers-chart g {
	cursor: pointer;
}

.scroll-btn {
	font-weight: 500;
}

.scroll-btn svg {
	width: 20px;
	margin-right: 2px;
	height: 19px;
}

.tag-list .bg-acordion-description-tag {
	margin-bottom: 10px;
	font-size: 12px;
}

.nodegraph-page .ant-tag.ant-tag-has-color,
.discon .ant-tag.ant-tag-has-color {
	color: rgba(0, 0, 0, 0.85);
	border: 1px solid rgb(167 167 167 / 85%);
}

.ant-table-layout-fixed table {
  table-layout: auto;
}

.nodegraph-page .edit-icon {
	cursor: pointer;

}

.nodegraph-page .edit-icon svg {
	fill: #27a6a4;
	width: 23px;
}

.edit-text input {
	border: 1px solid #d9d9d9 !important;
	outline: none;
	padding: 3px 8px;
	border-radius: 1px;
}

.edit-text svg {
	width: 28px;
	fill: #27a6a4;
	margin-left: 5px;
	cursor: pointer;
}

.edit-text .close svg {
	margin-left: 5px;
	width: 20px;
	height: 20px;
	margin-bottom: 0 !important;
}

.ant-btn-primary {
  background: #27A6A4;
  border-color: #27A6A4;
}

.ant-btn-primary:hover {
  background: #27A6A4;
  border-color: #27A6A4;
}

.ant-input-search-button:hover,
.ant-input-search-button:focus,
.ant-input-search-button:active {
  background-color: #27A6A4 !important; 
  border-color: #27A6A4 !important;   
  color: white !important;          
}